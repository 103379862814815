/* eslint no-underscore-dangle: ["error", { "allow": ["_accEmbed"] }] */
/* eslint no-console: ["error", { "allow": ["error", "warn"]}] */

// ES5 Object.assign() Polyfill
if (typeof Object.assign !== 'function') {
  Object.assign = function (target, varArgs) {
    'use strict'
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object')
    }

    let to = Object(target)

    for (let index = 1, len = arguments.length; index < len; index++) {
      let nextSource = arguments[index]

      if (nextSource != null) {
        for (let nextKey in nextSource) {
          if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
            to[nextKey] = nextSource[nextKey]
          }
        }
      }
    }
    return to
  }
}

/**
 * Polyfill for btoa
 */
if (typeof window.btoa !== 'function') {
  window.btoa = (input) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
    const str = String(input)
    for (
        let block, charCode, idx = 0, map = chars, output = '';
        str.charAt(idx | 0) || (map = '=', idx % 1);
        output += map.charAt(63 & block >> 8 - idx % 1 * 8)
    ) {
        charCode = str.charCodeAt(idx += 3 / 4);
        if (charCode > 0xFF) {
            console.error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
        }
        block = block << 8 | charCode;
    }
    return output
  }
}

const acceleratorEmbed = {
  options: {
    id: null, // string   (required) Component id
    postMessageTransmission: false, // Set this to true if you wish to transit data via post message instead of URL
    client: null, // string   (required) Component host
    token: null, // string   (optional) Registered user token to access private components
    analyticsUserId: null, // string (optional) Analytics user id to be able edit submission of public form
    submissionId: null, // string   (optional) Component submission id
    submissionStatus: null, // string   (optional) Submission status (valid|draft)
    el: null, // string   (optional) Target dom element
    theme: 'standard', // string   (optional) Component theme (currently supported: standard, travelocity, wotif)
    css: null, // string   (optional) URL of CSS file to inject (only HTTPS allowed)
    fields: [], // array    (optional) Data for pre-populate fields
    callback: null, // function (optional) Callback function
    preSubmitCallback: null, // function   (optional) Callback function on before saving
    preSubmitDraftCallback: null, // function    (optional) Callback function on before saving a draft
    placeholders: null, // object   (optional) Pass placeholders to replace text on form
    dataSources: null, // object   (optional) Pass options for configuring data sources
    errorOverrides: null,
    targets: [],
    params: null,
    secureUrl: true, // bool (optional) Determine whether to use HTTP or HTTPS for the embed source
  },
  init: (options) => {
    acceleratorEmbed.options = Object.assign(acceleratorEmbed.options, options)

    if (!acceleratorEmbed.options.id) {
      console.error('Parameter id is required.')
      return
    }

    if (!acceleratorEmbed.options.client) {
      console.error('Parameter client is required.')
      return
    }

    if (acceleratorEmbed.options.css && !/^https:\/\//i.test(acceleratorEmbed.options.css)) {
      console.warn('CSS file should be served over HTTPS.')
    }

    const element = acceleratorEmbed.options.el
      ? document.getElementById(acceleratorEmbed.options.el)
      : null

    const host = window.location.origin
    const iframeId = `acc-embed-${parseInt(Math.random() * 1e8, 10)}`

    // Define the initial param object this will be used for either transport method
    // These parameters are the bare essentials to get the to sites talking
    const commonParams = {
      host,
      iframeId,
      campaignId: acceleratorEmbed.options.id,
      token: acceleratorEmbed.options.token,
      analyticsUserId: acceleratorEmbed.options.analyticsUserId,
      css: acceleratorEmbed.options.css,
      theme: acceleratorEmbed.options.theme,
    }

    // Now only pass the additioanl params if postMessageTransmission is false
    // If its true we are going to send these values over later via postMessage
    const extendedParams = {
      fields: acceleratorEmbed.options.fields,
      placeholders: acceleratorEmbed.options.placeholders,
      dataSources: acceleratorEmbed.options.dataSources,
      errorOverrides: acceleratorEmbed.options.errorOverrides,
    }

    // Set the params object that contains all params. Depending on the data context
    // this could be used now or later
    acceleratorEmbed.options.params = extendedParams

    const contextParams = JSON.stringify((acceleratorEmbed.options.postMessageTransmission) ? commonParams : Object.assign(commonParams, extendedParams))
    const basePath = acceleratorEmbed.options.client
    const iframe = document.createElement('iframe')
    const scheme = acceleratorEmbed.options.secureUrl ? 'https' : 'http'
    let iframeSrc = `${scheme}://${basePath}/embed/${acceleratorEmbed.options.id}`
    iframeSrc += (acceleratorEmbed.options.submissionId) ? `/${acceleratorEmbed.options.submissionId}` : ''
    const queryParams = `?context=${btoa(unescape(encodeURIComponent(contextParams)))}`
    const ck = `&ck=${iframeId}`

    iframe.id = iframeId
    iframe.name = iframeId
    iframe.src = iframeSrc + queryParams + ck
    iframe.style.width = '100%'
    iframe.style.border = 'none'
    iframe.style.background = `#e1e1e1 url(${scheme}://${basePath}/assets/loading.gif) no-repeat center / 200px auto`

    if (element) {
      element.innerHTML = iframe.outerHTML
    } else {
      document.write(`<div class="_accEmbedWrapper">${iframe.outerHTML}</div>`)
    }

    document.getElementById(iframeId).onload = () => {
      document.getElementById(iframeId).contentWindow.postMessage({
        origin: window.location.href,
      }, '*')
    }

    window.addEventListener('message', acceleratorEmbed.onMessage, false)
  },
  onMessage: (e) => {
    if (typeof e.data.type !== 'undefined') {
      switch (e.data.type) {
        case 'accelerator.embed.resize':
          acceleratorEmbed.onResize(e)
          break
        case 'accelerator.embed.success':
          acceleratorEmbed.onSuccess(e)
          break
        case 'accelerator.embed.preSubmit':
          acceleratorEmbed.onPreSubmit(e)
          break
        case 'accelerator.embed.preSubmitDraft':
          acceleratorEmbed.onPreSubmitDraft(e)
          break
        case 'accelerator.embed.ready':
          acceleratorEmbed.onReady(e)
          break
        default:
      }
    }
  },
  onReady: (e) => {
    // If the child iframe posts back that it is ready then send a postmessage of data
    const { iframeId } = e.data
    if (acceleratorEmbed.options.postMessageTransmission) {
      document.getElementById(iframeId).contentWindow.postMessage({
        type: 'accelerator.embed.data',
        data: acceleratorEmbed.options.params,
      }, '*')
    }
  },
  onSuccess: (e) => {
    if (typeof e.data.success !== 'boolean') {
      console.error('Success must be boolean')
      return
    }

    if (typeof acceleratorEmbed.options.callback === 'function') {
      acceleratorEmbed.options.callback(e.data)
    }
  },
  onPreSubmit: (e) => {
    if (typeof acceleratorEmbed.options.preSubmitCallback === 'function') {
      acceleratorEmbed.options.preSubmitCallback(e.data)
    }
  },
  onPreSubmitDraft: (e) => {
    if (typeof acceleratorEmbed.options.preSubmitDraftCallback === 'function') {
      acceleratorEmbed.options.preSubmitDraftCallback(e.data)
    }
  },
  onResize: (e) => {
    if (typeof e.data.height !== 'number') {
      console.error('Height must be number.')
      return
    }

    const iframe = document.getElementById(e.data.iframeId)
    if (iframe === null) {
      console.error('Invalid iframe ID')
      return
    }

    iframe.style.height = `${e.data.height}px`
  },
}

function _accEmbed (options) {
  acceleratorEmbed.init(options)
}

window._accEmbed = _accEmbed
